import React, { useEffect } from "react";
import "./css/molduras.css"
import { Carousel } from 'antd';

function MBanner(){

    function onChange(a, b, c) {
        console.log(a, b, c);
    }
    useEffect(()=>{
        document.getElementById('tittle-page').classList.add('active');
    }, []);

    return(
        <div className="banner-moldura">
             <Carousel afterChange={onChange}>
                <div >
                    <div className="cont-img-moldu">
                        <h1 id="tittle-page">Molduras</h1>
                    </div>
                </div>
             </Carousel> 
            
        </div>
    );

}

export default MBanner


