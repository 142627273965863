import React from "react";
import './app.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Nav from "./components/layout/Nav";
import Footer from "./components/layout/Footer";
import Sobre from "./screens/Sobre"
import Molduras from "./screens/Molduras"
import Orcamento from "./screens/Orcamento"
import Home from "./screens/Home"
import Terms from "./screens/Terms";


function App() {
  return (
          <div className="App">
            <Router>
              <Nav/>
              <Routes> 
                  <Route path="/molduras" element={<Molduras title="Molduras - " />} />
                  <Route path="/orcamento" element={<Orcamento title="Orçamento - " />} />
                  <Route path="/sobre" element={<Sobre title="Sobre - " />} />
                  <Route path="/" element={<Home id="page-home" title="-Fabrica da Moldura -" />} />
                  <Route path="/termos" element={<Terms id="page-terms" title="-Termos Fabrica da Moldura -" />} />
                  <Route path='*' exact={true} element={<Home id="page-home" title="-Fabrica da Moldura -" />}/>
              </Routes>
              <Footer/>
            </Router>
           
          </div>
      
  );
}

export default App;
