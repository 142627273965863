import React, { useEffect } from "react";
import './Footer.css';
import Logo from '../../imgs/logo-footer.png'
import { AiOutlineInstagram } from 'react-icons/ai'
import {FaPinterestSquare , FaFacebookF} from 'react-icons/fa'

function Footer(){
    return(
     <footer>
         <section className="footer-top">
            <ul className="content-footer footer-ul">
                <div className="footer-colum footer-colum-1">
                    <div className="img-horario-footer">
                        <img src={Logo}></img>
                        <p>
                            Horário:  Seg. a Sexta das 09h às 17h, <br/> Sábados das 09h as 13h.</p>
                    </div>
                    <div className="icons-footer">
                            <a href="https://www.instagram.com/fabricadamoldura/">
                                <div className="icon-box"><AiOutlineInstagram/></div>
                            </a>
                            <a href="https://www.facebook.com/FabricaDaMolduraOficial">
                                <div className="icon-box"><FaFacebookF/></div>
                            </a>
                            <a href="https://br.pinterest.com/fabricadamoldura/_created/">
                                <div className="icon-box"><FaPinterestSquare/></div>
                            </a>
                    </div>
                </div>
                <div className="footer-colum text-colum footer-colum-2">
                    <h2>Atendimento</h2>
                    <p>Email:</p>
                    <p className="email-p">atendimento@fabricadamoldura.com.br</p>
                    <p>Telefone:</p>
                    <p>(11) 4584.0750 / (11) 4584.5295</p>
                    <p>Local:</p>
                    <p>Loja: Av. José Mezzalira, 930 - Bairro Caxambu - Jundiai/SP<br/></p>
                </div>
                <div className="footer-colum text-colum footer-colum-3">
                    <h2>Minha Conta</h2>
                    <a><p>Menu</p></a>
                    <a><p>Pedidos</p></a>
                    <a><p>Informações</p></a>
                </div>
                <div className="footer-colum text-colum footer-colum-4">
                    <h2>Sobre</h2>
                    <a href="https://loja.fabricadamoldura.com.br/categoria-produto/colecoes/"><p>Acervo de Imagens</p></a>
                    <a><p>Acervo de Molduras</p></a>
                    <a href="https://www.fabricadamoldura.com.br/termos/"><p>Termos e Condições</p></a>
                </div>
            </ul>
         </section>
         <section className="bar-footer">
             <div className="content-footer direitos-footer">
             © Todos os direitos reservados por Fábrica Da Moldura
             </div>
         </section>
     </footer>
    )
}

export default Footer