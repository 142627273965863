import React, { useEffect } from "react";

import HBanner from '../components/HBanner'
import HQuemsomos from '../components/HQuemsomos'
import HPersonalizado from '../components/HPersonalizado'
import HSlidesmodelo from '../components/HSlidesmodelo'
import HQuestions from '../components/HQuestions'
import Fade from 'react-reveal/Fade'
import TerBanner from "../components/TerBanner";

function Terms(props){
    
    useEffect( () => {
        document.title = props.title;
           
    }, []);

    return(
        <div className="home">
            <TerBanner/>
            <div className="content-max" style={{padding: "2rem"}}>
            <h2>
    1. Termos de Uso e Compras
</h2>
<p>
    Ao acessar o site
    <a href="https://politicaprivacidade.com/www.fabricadamoldura.com.br">
        Fábrica da Moldura
    </a>
    , concorda em cumprir estes termos de serviço, todas as leis e regulamentos
    aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis
    locais aplicáveis. Se você não concordar com algum desses termos, não
    poderá usar ou acessar este site. Os materiais contidos neste site são
    protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
</p>
<h2>
    2. Uso de Licença
</h2>
<p>
    É concedida permissão no site Fábrica da Moldura somente a visualização
    transitória pessoal e não comercial. Esta é a concessão de uma licença, não
    uma transferência de título e, sob esta licença, você não pode:
</p>
<ol start="1" type="1">
    <li>
        modificar ou copiar os materiais;
    </li>
    <li>
        usar os materiais para qualquer finalidade comercial;
    </li>
    <li>
        tentar descompilar ou fazer engenharia reversa de qualquer software
        contido no site Fábrica da Moldura;
    </li>
    <li>
        remover quaisquer direitos autorais ou outras notações de propriedade
        dos materiais; ou
    </li>
    <li>
        transferir os materiais para outra pessoa ou 'espelhar' os materiais em
        qualquer outro servidor ou site;
    </li>
    <li>
        remover quaisquer informações do servidor, sem consentimento.
    </li>
</ol>
<p>
    Esta licença estará automaticamente em uso e concordância do usuário ao
    navegar pelo site e, estará o mesmo ciente que deverão ser respeitadas as
    condições de uso citado.
</p>
<h2>
    3. Isenção de responsabilidade
</h2>
<ol start="1" type="1">
    <li>
        Os materiais no site da Fábrica da Moldura são fornecidos 'como estão'.
        Fábrica da Moldura não oferece garantias, expressas ou implícitas, e,
        por este meio, isenta e nega todas as outras garantias, incluindo, sem
        limitação, garantias implícitas ou condições de comercialização,
        adequação a um fim específico ou não violação de propriedade
        intelectual ou outra violação de direitos.
    </li>
    <li>
        Além disso, a Fábrica da Moldura não garante ou faz qualquer
        representação relativa à precisão, aos resultados prováveis ​​ou à
        confiabilidade do uso dos materiais em seu site ou de outra forma
        relacionado a esses materiais ou em sites vinculados a este site.
    </li>
    <li>
        A Fábrica da Moldura não garante que o produto ou serviço apresentado
        no site seja condizente com as expectativas do usuário, visto que as
        imagens apresentadas são, em sua maioria, computadorizadas e recriadas
        virtualmente; ainda assim, é garantido ao usuário comprador que
        prestamo-nos ao máximo de atenção aos detalhes, além da qualidade do
        material fornecido, devendo-se condizer a isenção acima a justificativa
        de possíveis expectativas acima do limite da realidade presente.
    </li>
</ol>
<h2>
    4. Limitações
</h2>
<p>
    Buscando seguir em conformidade ao direito de proteção do usuário e seus
    dados, conforme a Lei n 13.709/18 (Lei de Proteção de Dados – LGPD),
    tomamos todas as providências possíveis sob os conhecimentos alcançáveis e,
    prezamos ao máximo possível resguardar com segurança qualquer dado recebido
    dentro de nosso site, contra possíveis vazamentos. Fica excluído este
    último, no que se deve o fato do compartilhamento proposital com fontes de
    terceiros confiáveis, que descreveremos mais abaixo. Para transações dentro
    do site, fica registrado ainda que, a Fábrica da Moldura não armazena ou
    mesmo recebe dados ao que se diz: números de cartão de crédito, informações
    bancárias ou qualquer outro tipo de dado que se deva respeito ao exercício
    de compra; estes ficam todos resguardados com a integradora parceira
    escolhida pelo usuário comprador no ato da compra, a qual faz as próprias
    garantias. Nos limitamos no sentido que se diz a ataques cibernéticos e,
    deixamos claro que ainda perante providências tomadas, somos todos
    vulneráveis ao ambiente online e, no caso deste, poderão ser vazados os
    dados registrados dentro do site, ao qual, como citado, não se aplica
    aqueles referentes ao pagamento de um item desejado.
</p>
<h2>
    5. Precisão dos materiais
</h2>
<p>
    Os materiais exibidos no site da Fábrica da Moldura podem incluir erros
    técnicos, tipográficos, fotográficos ou computadorizados. A Fábrica da
    Moldura não garante que qualquer material em seu site seja preciso,
    completo ou atual. A Fábrica da Moldura pode fazer alterações nos materiais
    contidos em seu site a qualquer momento, sem aviso prévio. No entanto, A
    Fábrica da Moldura não se compromete a atualizar os materiais.
</p>
<h2>
    6. Links
</h2>
<p>
    A Fábrica da Moldura não analisou todos os sites vinculados ao seu site e
    não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de
    qualquer link não implica endosso por Fábrica da Moldura do site. O uso de
    qualquer site vinculado é por conta e risco do usuário.
</p>
<h2>
    7. Compras pelo site
</h2>
<p>
    7.1. As compras efetuadas serão despachadas em dias úteis (segunda a
    sexta-feira), não incluindo feriados;
</p>
<p>
    7.2. A Fábrica da Moldura não se responsabilizará por prazos
    disponibilizados na plataforma, as quais poderão não ser precisas
    suficientemente para chegar a tempo do esperado pelo usuário comprador.
</p>
<p>
    7.3. O usuário comprador deverá estar ciente, em caso de erro ao inserir
    seu endereço, que sua compra retornará até nossa unidade física após o
    envio do(s) produto(s), para que somente então seja realizado um novo
    envio, ou então o extorno do valor da compra, se desejado. Não será de
    responsabilidade da Fábrica da Moldura eventuais custos por envio
    duplicado.
</p>
<p>
    7.4. Em caso de não comparecimento do cliente ou responsável pelo
    recebimento da entrega, o produto retornará para a transportadora
    contratada, havendo duas próximas tentativas que, se não atendidas
    novamente, será retornada a devida unidade de origem do despacho.
</p>
<p>
    7.5. A Fábrica da Moldura se reserva ao compromisso de satisfação do
    cliente e, total atenção ao mesmo. Qualquer problema e transtorno gerado em
    sua experiência de compra será analisada por um dos agentes da equipe,
    visando a melhor resolução para os dois lados e, procurando uma boa
    experiência para com o cliente.
</p>
<p>
    7.6. A Fábrica da Moldura não poderá se responsabilizar por atrasos e ou
    falhas no cumprimento dos termos, caso estes sejam causados por fenômenos
    fora de nosso controle razoável, os quais:
</p>
<p>
    (a) problemas com transportadoras contratadas;
</p>
<p>
    (b) greves, bloqueios ou quaisquer outras ações do gênero;
</p>
<p>
    (c) explosões; inundações; incêndios; desastres naturais ou epidemias;
</p>
<p>
    (d) impossibilidade e inviabilização do uso de meios de transporte usados
    para entrega do produto.
</p>
<h2>
    8. Direito de arrependimento
</h2>
<p>
    1. Você pode se arrepender da compra e rescindir o Contrato a qualquer
    momento, no prazo máximo de 07 (sete) dias corridos, a contar do dia
    posterior ao do recebimento do(s) Produto(s).
    <br/>
    <br/>
    2. No caso de arrependimento, é sua responsabilidade cuidar devidamente
    do(s) Produto(s). O(s) Produto(s) deve(m) estar na condição original de
    recebimento e com a sua embalagem original, isso inclui estar plastificado,
    para que entendamos que não houve uso. Neste caso, você receberá um
    reembolso total do preço pago pelo(s) Produto(s), de acordo com a POLÍTICA
    DE DEVOLUÇÃO.
</p>
<p>
    (a) em casos de devolução, o cliente será orientado com instruções para a
    devolução do(s) produto(s) através do e-mail e, se necessário, contato
    direto via telefone/celular;
</p>
<p>
    (b) os produtos deverão estar exatamente como foram entregues, também sendo
    da responsabilidade do cliente reembalar (caso tenha aberto a embalagem)
    exatamente como fora enviado;
</p>
<p>
    (c) Caso o produto chegue com avarias a nossa unidade, não estando
    exatamente como inicialmente recebido, o usuário comprador concorda que
    assumirá os custos de danos ou devoluções “com itens incompletos”.
</p>
<h3>
    Modificações
</h3>
<p>
    A Fábrica da Moldura pode revisar estes termos de serviço do site a
    qualquer momento, sem aviso prévio. Ao usar este site, você concorda em
    ficar vinculado à versão atual desses termos de serviço.
</p>
<h3>
    Lei aplicável
</h3>
<p>
    Estes termos e condições são regidos e interpretados de acordo com as leis
    da Fábrica da Moldura e você se submete irrevogavelmente à jurisdição
    exclusiva dos tribunais naquele estado ou localidade.
</p>
<h2>
    Políticas de Privacidade do Usuário
</h2>
<p>
    A sua privacidade é importante para nós. É política da Fábrica da Moldura
    respeitar a sua privacidade em relação a qualquer informação, além de
    guarda-las sigilosamente, mantendo apenas caráter de uso profissional para
    melhorar a experiência da comunidade online ligada aos serviços do
    seguimento.
</p>
<p>
    Solicitamos informações pessoais para melhorar a experiência com o cliente
    e manter fácil contato em futuras operações de interesse do mesmo.
    Fazemo-lo por meios justos e legais, com o seu conhecimento e
    consentimento. Também informamos por que estamos coletando e como será
    usado.
</p>
<p>
    Retemos as suas informações dispostas pelo tempo necessário para fornecer o
    serviço solicitado, além da possibilidade de uso em futuras campanhas
    publicitárias que sejam do interesse do mesmo. Quando armazenamos dados,
    protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e
    roubos, bem como acesso, divulgação, cópia, uso ou modificação não
    autorizados.
</p>
<p>
    Não compartilhamos informações de identificação pessoal publicamente, porém
    podemos usa-las no compartilhamento com parceiros comerciais e servidores
    de terceiros, conforme julgamos necessário. Além disso, dispomos dos dados
    em caso de pedido de autoridades competentes, para fins judiciários e
    aplicações decretadas por lei no que diz respeito ao cumprimento das
    obrigações.
</p>
<p>
    O nosso site pode ter links para sites externos que não são operados por
    nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas
    desses sites e não podemos aceitar responsabilidade por suas respectivas
    <a href="https://politicaprivacidade.com/#_blank">
        políticas de privacidade
    </a>
    .
</p>
<p>
    Você é livre para recusar a nossa solicitação de informações pessoais,
    entendendo que talvez não possamos fornecer alguns dos serviços desejados,
    além de ofertas previamente informadas ao consumidor por meio de algum dos
    canais de livre acesso.
</p>
<p>
    O uso continuado de nosso site será considerado como aceitação de nossas
    práticas em torno de privacidade e informações pessoais. Se você tiver
    alguma dúvida sobre como lidamos com seus dados e informações pessoais,
    leia abaixo atentamente; não reservaremos o tempo de nossos agentes para
    dar satisfação aos fatos aqui descritos de seus dados, justamente
    concedidos após aceitar os termos aqui presentes e descritos.
</p>
<h2>
    Política de Cookies da Fábrica da Moldura
</h2>
<h3>
    O que são cookies?
</h3>
<p>
    Como é prática comum em quase todos os sites profissionais, este site usa
    cookies, que são pequenos arquivos baixados no seu navegador, para melhorar
    sua experiência. Esta página descreve quais informações eles coletam, como
    as usamos e por que às vezes precisamos armazenar esses cookies. Também
    compartilharemos como você pode impedir que esses cookies sejam
    armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos
    elementos da funcionalidade do site.
</p>
<p>
    Isso significa que sua experiência de navegação pode ser influenciada
    negativamente, retirando o proveito do propósito principal dos mesmos por
    nós inclusos na plataforma.
</p>
<h3>
    Como usamos os cookies?
</h3>
<p>
    Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na
    maioria dos casos, não existem opções padrão do setor para desativar os
    cookies sem desativar completamente a funcionalidade e os recursos que eles
    adicionam a este site. É recomendável que você deixe todos os cookies, se
    não tiver certeza se precisa ou não deles, caso sejam usados ​​para
    fornecer um serviço que você usa. (por exemplo: a adição de um produto ou
    serviço ao carrinho que permanecerá neste, mesmo após sair do site e
    retornar mais tarde)
</p>
<h3>
    Desativar cookies
</h3>
<p>
    Você pode impedir a configuração de cookies ajustando as configurações do
    seu navegador (consulte a Ajuda do seu navegador para saber como fazer
    isso). Esteja ciente de que a desativação de cookies afetará a
    funcionalidade deste e de muitos outros sites que você visita. A
    desativação de cookies geralmente resultará na desativação de determinadas
    funcionalidades e recursos deste site. Portanto, é recomendável que você
    não desative os cookies.
</p>
<p>
    Ainda, podem existir cookies de caráter obrigatório em sua experiência, dos
    quais você não terá o direito de desabilitar e, caso esteja navegando pelo
    site, estará confirmando sua autorização de uso, enquanto dentro do site, a
    partir do aparelho utilizado.
</p>
<h3>
    Cookies que definimos
</h3>
<ul type="disc">
    <li>
        Cookies relacionados à conta
        <br/>
        <br/>
        Se você criar uma conta conosco, usaremos cookies para o gerenciamento
        do processo de inscrição e administração geral. Esses cookies
        geralmente serão excluídos quando você sair do sistema, porém, em
        alguns casos, eles poderão permanecer posteriormente para lembrar as
        preferências do seu site ao sair.
        <br/>
        <br/>
    </li>
    <li>
        Cookies relacionados ao login
        <br/>
        <br/>
        Utilizamos cookies quando você está logado, para que possamos lembrar
        dessa ação. Isso evita que você precise fazer login sempre que visitar
        uma nova página. Esses cookies são normalmente removidos ou limpos
        quando você efetua logout para garantir que você possa acessar apenas a
        recursos e áreas restritas ao efetuar login, além de utilizar a função
        de “limpeza” do cache do navegador utilizado.
        <br/>
        <br/>
    </li>
    <li>
        Cookies relacionados a boletins por e-mail
        <br/>
        <br/>
        Este site oferece serviços de assinatura de boletim informativo ou
        e-mail e os cookies podem ser usados ​​para lembrar se você já está
        registrado e se deve mostrar determinadas notificações válidas apenas
        para usuários inscritos / não inscritos. Poderemos enviar notificações
        para lembra-lo de produtos deixados no carrinho ou novidades dispostas
        pela marca.
        <br/>
        <br/>
    </li>
    <li>
        Pedidos processando cookies relacionados
        <br/>
        <br/>
        Este site oferece facilidades de comércio eletrônico ou pagamento e
        alguns cookies são essenciais para garantir que seu pedido seja
        lembrado entre as páginas, para que possamos processá-lo adequadamente.
        <br/>
        <br/>
    </li>
    <li>
        Cookies relacionados a pesquisas
        <br/>
        <br/>
        Periodicamente, oferecemos pesquisas e questionários para fornecer
        informações interessantes, ferramentas úteis ou para entender nossa
        base de usuários com mais precisão. Essas pesquisas podem usar cookies
        para lembrar quem já participou numa pesquisa ou para fornecer
        resultados precisos após a alteração das páginas.
        <br/>
        <br/>
    </li>
    <li>
        Cookies relacionados a formulários
        <br/>
        <br/>
        Quando você envia dados por meio de um formulário como os encontrados
        nas páginas de contato ou formulários de interesse do produto, os
        cookies podem ser configurados para lembrar os detalhes do usuário para
        correspondência futura e uso em outras redes, para contato direto e
        indireto de publicidade.
        <br/>
        <br/>
    </li>
    <li>
        Cookies de preferências do site
        <br/>
        <br/>
        Para proporcionar uma ótima experiência neste site, fornecemos a
        funcionalidade para definir suas preferências de como esse site é
        executado quando você o usa. Para lembrar suas preferências, precisamos
        definir cookies para que essas informações possam ser chamadas sempre
        que você interagir com uma página for afetada por suas preferências.
    </li>
    <li>
        Cookies de monitoramento de URLs
    </li>
</ul>
<p>
    Para entender melhor o que nossos clientes desejam encontrar e realmente os
    interessa, utilizamos cookies e ferramentas que podem apontar o tempo
    passado em uma devida URL; qual parte desta ele ficou por maior tempo; além
    de ações realizadas, como cliques em botões interativos. Essas informações
    podem ser compartilhadas com ferramentas de terceiros se julgarmos
    necessário também.
</p>
<h3>
    Cookies de Terceiros
</h3>
<p>
    Em alguns casos especiais, também usamos cookies fornecidos por terceiros
    confiáveis. A seção a seguir detalha quais cookies de terceiros você pode
    encontrar através deste site.
</p>
<ul type="disc">
    <li>
        Este site usa o Google Analytics, que é uma das soluções de análise
        mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como
        você usa o site e como podemos melhorar sua experiência. Esses cookies
        podem rastrear itens como quanto tempo você gasta no site e as páginas
        visitadas, para que possamos continuar produzindo conteúdo atraente e
        de qualidade.
    </li>
</ul>
<p>
    Para mais informações sobre cookies do Google Analytics, consulte a página
    oficial do Google Analytics.
</p>
<ul type="disc">
    <li>
        As análises de terceiros são usadas para rastrear e medir o uso deste
        site, para que possamos continuar produzindo conteúdo atrativo. Esses
        cookies podem rastrear itens como o tempo que você passa no site ou as
        páginas visitadas, o que nos ajuda a entender como podemos melhorar o
        site para você.
    </li>
    <li>
        Periodicamente, testamos novos recursos e fazemos alterações subtis na
        maneira como o site se apresenta. Quando ainda estamos testando novos
        recursos, esses cookies podem ser usados ​​para garantir que você
        receba uma experiência consistente enquanto estiver no site, enquanto
        entendemos quais otimizações os nossos usuários mais apreciam.
    </li>
    <li>
        À medida que vendemos produtos, é importante entendermos as
        estatísticas sobre quantos visitantes de nosso site realmente compram
        e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso
        é importante para você, pois significa que podemos fazer previsões de
        negócios com precisão que nos permitem analisar nossos custos de
        publicidade e produtos para garantir o melhor preço possível.
    </li>
    <li>
        O serviço Google AdSense que usamos para veicular publicidade usa um
        cookie DoubleClick para veicular anúncios mais relevantes em toda a Web
        e limitar o número de vezes que um determinado anúncio é exibido para
        você.
        <br/>
        Para mais informações sobre o Google AdSense, consulte as FAQs oficiais
        sobre privacidade do Google AdSense.
    </li>
    <li>
        Utilizamos anúncios para compensar os custos de funcionamento deste
        site e fornecer financiamento para futuros desenvolvimentos. Os cookies
        de publicidade comportamental usados ​​por este site foram projetados
        para garantir que você forneça os anúncios mais relevantes sempre que
        possível, rastreando anonimamente seus interesses e apresentando coisas
        semelhantes que possam ser do seu interesse.
    </li>
    <li>
        Alguns parceiros anunciam em nosso nome e os cookies de rastreamento de
        afiliados simplesmente nos permitem ver se nossos clientes acessaram o
        site através de um dos sites de nossos parceiros, para que possamos
        creditá-los adequadamente e, quando aplicável, permitir que nossos
        parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para
        fazer uma compra.
    </li>
    <li>
        Este site utiliza também o Cookie de compartilhamento de dados com o
        Facebook, o qual entregamos dados parecidos aos citados acima, além de
        informações complementares do usuário no ato da compra. Fazemo-os para
        medir nossa publicidade dentro da plataforma e garantir a efetividade
        da mensagem que desejamos transmitir aos compradores em potencial. Seus
        dados são enviados em “hashes”, que são formatos de código que tornam o
        usuário “anônimo” para a ferramenta de otimização e carregamento do
        Facebook. Por este motivo, podemos lhe transmitir com segurança que
        seus dados não serão armazenados publicamente, nem serão vistos por
        outras pessoas.
    </li>
</ul>
<h3>
    Compromisso do Usuário
</h3>
<p>
    O usuário se compromete a fazer uso adequado dos conteúdos e da informação
    que a Fábrica da Moldura oferece no site com caráter enunciativo,
    respeitando nossas ações e, tendo ciência de que estas foram feitas com
    prévio aviso antes de qualquer informação por nós divulgada com terceiros
    citados ou não citados.
</p>
<p>
    O usuário também se compromete a não causar danos aos sistemas físicos
    (hardwares) e lógicos (softwares), nem roubar informações da Fábrica da
    Moldura, de seus fornecedores ou terceiros, para retirar dados, introduzir
    ou disseminar vírus, trojans ou quaisquer outros sistemas de hardware ou
    software que sejam capazes de causar danos anteriormente mencionados. Em
    qualquer caso que você se sinta incomodado com nossos serviços e formas de
    trabalhar, estaremos a disposição para uma conversa construtiva, buscando
    melhorar aquilo que nos é apontado como defeito de caráter comercial e
    social.
</p>
<h3>
    Mais informações
</h3>
<p>
    Esperamos que esteja esclarecido e, como mencionado anteriormente, se
    houver algo que você não tem certeza se precisa ou não, geralmente é mais
    seguro deixar os cookies ativados, caso interaja com um dos recursos que
    você usa em nosso site.
</p>
<p>
    Esta política é efetiva a partir de <strong>Março/2022</strong>.
</p>
 
            <br/>
            </div>
            
        </div>
    );
}

export default Terms