import React, {useEffect} from "react";
import Lottie from 'react-lottie-player'
import "./css/home.css";
import { Carousel } from 'antd';
import lottieJson from './lottie.json'



function HBanner(){

    function onChange(a, b, c) {
        console.log(a, b, c);
    }

    useEffect(()=>{
        document.getElementById('box-text-home').classList.add('active');
    }, []);

    const contentStyle = {
        height: '650px',
        color: '#fff',
        lineHeight: '650px',
        textAlign: 'center',
        background: '#b62a2a',
      };
    return(
        <div className="banner-home">
             <Carousel afterChange={onChange}>
                <div >
                    <div className="cont-img">
                        <div id="box-text-home" className="box-text-home">
                            <h2>
                                Já são 2 décadas preenchendo paredes</h2>
                            <p>
                                Role para baixo e conheça mais sobre nossas opções!
                            </p>

                        </div>
                        <Lottie
                            className="lottie-scroll"
                                loop
                                animationData={lottieJson}
                                play
                                />
                    </div>
                    
                </div>
               
             </Carousel> 
             <div className="SVG-Banner">
             </div>
        </div>
    );

}

export default HBanner


