import React, { useEffect } from "react";
import OBanner from '../components/OBanner'
import OConteudo from '../components/OConteudo'
import '../components/css/orcamento.css'
import Fade from 'react-reveal/Fade'
function Orcamento(props){
    
    useEffect( () => {
        document.title = props.title
    }, []);
    
    return (
        <div className="orcamento">
            <OBanner tittle={props.title}/>
            <Fade left>
                <OConteudo/>
            </Fade>
           
        </div>
    );
}

export default Orcamento