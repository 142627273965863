import React, { useEffect, useState } from "react";
import QuestionIcon from '../imgs/questionIcon.png'
import Fade from 'react-reveal/Fade';
function HQuestions(){
	const [aberto, setAberto] = useState('question_1');

	function abrirContent(e) {
	 	if(aberto != e){
			var spanId = document.getElementById('span-'+e);
			spanId.innerHTML = '-'
			document.getElementById('return-'+e).style.display = 'block'
			
			setTimeout(() => {
				document.getElementById('return-'+e).classList.add('active');
			}, 10);
			setAberto(e);
			if(aberto != ''){
				document.getElementById('return-'+aberto).classList.remove('active')
				setTimeout(() => {
					document.getElementById('return-'+aberto).style.display = 'none'
				}, 500);
				document.getElementById('return-'+aberto).style.display = 'none'
				document.getElementById('span-'+ aberto).innerHTML = '+'
			}
		 }else if(aberto == e){
				if(aberto != ''){
					var spanId = document.getElementById('span-'+aberto);
					document.getElementById('return-'+e).classList.remove('active');
					setTimeout(() => {
						document.getElementById('return-'+e).style.display = 'none'
					}, 500);
					spanId.innerHTML = '+'
					setAberto('');
				}
		 }
	}


 return(
  <div className="ask-question-hquestion">
		<div className="img-ask-hquestion">
			<img src={QuestionIcon}></img>
		</div>
		<div className="text-ask-hquestion">
			<h2> Perguntas Frequentes<hr></hr></h2>
			<ul className="ul-ask-hquestion">
				<li className="li-question" onClick={() => {abrirContent('question_1')}}>
					<div className="tittle-question"><span className="box-span-question" id="span-question_1">+</span> Qual o prazo de entrega?</div>
					<div className="p-div-hquestion" id="return-question_1">O prazo para itens personalizados pode variar, de 2 a 5 dias uteís</div>
				</li>
				<li className="li-question"  onClick={() => {abrirContent('question_2')}}>
					<div className="tittle-question"><span className="box-span-question" id="span-question_2">+</span> Qual o valor para emoldurar?</div>
					<div className="p-div-hquestion" id="return-question_2">A pergunta só pode ser respondida tendo consigo as seguintes respostas: <br/> O que você deseja emoldurar? Qual a medida total do quadro, levando em base a medida do item ou dimensões a preencher em sua parede. <br/> Com essas simples questões (e a indicação da moldura desejada), você facilita o atendimento e, melhora as chances de atingir as expectativas desejadas!</div>
				</li>
				<li className="li-question"  onClick={() => {abrirContent('question_3')}}>
					<div className="tittle-question"><span className="box-span-question" id="span-question_3">+</span> Vocês fazem molduras personalizadas?</div>
					<div className="p-div-hquestion" id="return-question_3">É possível visualizar todas as opções disponíveis em nossa aba "Molduras". Nós definitivamente não "criamos" um modelo único de moldura, sendo que, o que realmente dispomos está em nosso catálogo.</div>
				</li>
				<li className="li-question"  onClick={() => {abrirContent('question_4')}}>
					<div className="tittle-question"><span className="box-span-question" id="span-question_4">+</span>Vocês vendem moldura em barra?</div>
					<div className="p-div-hquestion" id="return-question_4">Não, nós não vendemos. A Fábrica da Moldura tem como propósito único entregar quadros e montagens em moldura.</div>
				</li>
				<li className="li-question"  onClick={() => {abrirContent('question_5')}}>
					<div className="tittle-question"><span className="box-span-question" id="span-question_5">+</span> Vocês vendem em atacado? </div>
					<div className="p-div-hquestion" id="return-question_5">Sim! Para mais informações, utilize a aba "Contate-nos". <br/> Nosso WhatsApp é SOMENTE para atendimento a montagens personalizadas.</div>
				</li>
			</ul>
		</div>
  </div>		
 );
}

export default HQuestions