import React, { useEffect, useState } from 'react';
import { PageHeader, Select } from 'antd';
import { useNavigate } from 'react-router-dom'
import './Main.css'
import {Image, Pagination} from 'antd';
import {Bases} from '../services/api_molduras'
import Produto from './Produto'

function Main(props){
    const navigate = useNavigate();
    const [conteudo, SetConteudo] = useState();
    const [selectTipo, SetSelectTipo] = useState('all');
    const [selectCor, SetSelectCor] = useState('all');
    const [quantidadepagina, setQuantidadepagina] = useState()
    const [pagina, setPagina] = useState({
        current: 1,
      }) 

    useEffect(() => {
            buscar('all', 'all', 1);  
        }, []);
    
    useEffect(() =>{
        buscar(selectTipo, selectCor, 1);
    }, [selectTipo, selectCor]);

    useEffect(() =>{
        buscar(selectTipo, selectCor, pagina.current);
    }, [pagina]);

    function buscar(tipo, cor, page) {
        return Bases.get('account.php?tipo='+tipo+'&cor='+cor+'&page='+page).then(function (response) {
            SetReturnArray(response.data)
        }) };

    function SetReturnArray(retorno){
        if(retorno != null){ var Array1 = [];
            setQuantidadepagina(retorno[0].quantPage)
            for(let i = 0; i < retorno.length; i++){
                let thumb = retorno[i].thumb;
                let name = retorno[i].name;
                let keys = retorno[i].id;

                Array1.push(
                    <Produto keys={keys} sku={name} thumb={thumb} />
           )};

         SetConteudo(Array1)
        }else{ SetConteudo( <div> Nenhum Produto Encontrado..</div>)}
    }
    // // Trocar De Pagina

    function onChange(page){
        console.log(page);
        setPagina({
          current: page,
        });
    };
    return(<>
        <div className='header-main'>
                <PageHeader
                    className="site-page-header"
                    onBack={() => navigate('/')}
                    title={props.tittle}
                    subTitle="Seleção"
                />
                <div className='input-header'>
                    <select defaultValue="all" id="select-tipo" onChange={(event) =>{ SetSelectTipo(event.target.value)}}>
                        <option value="all">Todos</option>
                        <option value="caixa">Caixa</option>
                        <option value="detalhada">Detalhada</option>
                        <option value="lisa">Lisas</option>
                    </select>
                     <select defaultValue="all" id="select-cor" onChange={(event) =>{ SetSelectCor(event.target.value)}}>
                        <option value="all">Todos</option>
                        <option value="madeira">Madeira</option>
                        <option value="tabaco">Tabaco</option>
                        <option value="dourada">Dourada</option>
                         <option value="prata">Prata</option>
                        <option value="amarelo">Amarelo</option>
                        <option value="azul">Azul</option>
                        <option value="bege">Bege</option>
                        <option value="laranja">Laranja</option>
                        <option value="preto">Preto</option>
                        <option value="branco">Branco</option>
                        <option value="rosa">Rosa</option>
                        <option value="roxo">Roxo</option>
                        <option value="verde">Verde</option>
                        <option value="vermelho">Vermelho</option>
                       
                       
                    </select>
                </div>
        </div>
        <div className='main-content'>
          <div className="items-container">
                {conteudo}    
          </div>
             <div className='buttons-pages-moldura'>
                    <Pagination className="btn-page" current={pagina.current} onChange={onChange} total={quantidadepagina} />     
            </div>
                </div>
                
    </>)

}
export default Main