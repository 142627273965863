import React, { useEffect, useState } from "react";
import {Hmolduras, Hmontagem} from './_buildModelo';
import Fade from 'react-reveal/Fade';

function HSlidesmodelo(){

    function selectReturn(value){
        if(value == 'Hmolduras'){
            return <Hmolduras/>
        }else if(value == 'Hmontagem'){
            return <Hmontagem/>
        }
    }

    const [modelo1, setModelo] = useState(selectReturn('Hmolduras'));
    const [NomeMod, setNomeMod] = useState('Hmolduras');

    // Carregar Elemento
    useEffect( ()=>{
        document.getElementById(NomeMod).classList.add('active');
    }, []);

    useEffect( ()=>{
        document.getElementById(NomeMod).classList.add('active');
    }, [NomeMod])

    function selectModule(e){
        if(e.currentTarget.id != NomeMod){
            document.getElementById(NomeMod).classList.remove('active');
            setModelo(selectReturn(e.currentTarget.id));
            setNomeMod(e.currentTarget.id);
        }
    }

    return(
        <div className="container-modelo-hs">
            <div className="tittle-hs">
                <Fade top>
                    <h1 id="tittle-h1-hs">Modelos de Estilização<hr></hr></h1>
                </Fade>         
            </div>
            <div id="div-modelo" className="content-select-hs">
                    <div className="button-select" id="Hmolduras" onClick={selectModule}  >
                        <p>Molduras</p>
                    </div>
                    <div  className="button-select" id="Hmontagem" onClick={selectModule} >
                        <p>Montagem</p>
                    </div>
            </div>
            <div className="conteudo-modelo-hs">
                    {modelo1}
            </div>
        </div>
    );
}

export default HSlidesmodelo