import React from "react";
import sobre from "../imgs/sobre.JPG"
import sobre2 from '../imgs/a.jpg'
import sobre3 from '../imgs/aa.jpg'
import {FaIndustry} from "react-icons/fa"
import {CgCoffee} from "react-icons/cg"
import {BsTruck} from "react-icons/bs"
import {SiGooglemaps} from "react-icons/si"
function SInfo(){
    return(
<div className="Sinfo">
    <div className="first-container">
        <div className="first-image-container">
            <div className="bg-detail">
                <FaIndustry className="icon-image-first"/>
            </div>
            <img src={sobre3}></img>
        </div>
        <div className="first-text-container">
            <div className="align-text-box">
                <h1>
                O que é a "Fábrica da Moldura"?
                </h1>
                <p className="sub-tittle">
                somos mais que apenas o nome indústrial soa...
                </p>
                <p className="p-text">
                    A Fábrica da Moldura surgiu, inicialmente, de uma ramificação do trabalho já em execução de nossa especialidade, molduras. <br/> No começo, o atendimento era feito a nível Brasil, já a 20 anos atrás (muito antes da ascensão da internet). Com o passar da primeira década do surgimento (2002), notamos a necessidade de trazer algo que faltava no mercado: a possibilidade de personalizar seu próprio quadro, com estilo e identidade única.
                </p>
            </div>
        </div>
    </div>
    <div className="three-container">
        <div className="three-text-container">
            <div className="align-text-box">
                <p className="sub-tittle">
                O nosso compromisso, com você, é profissional.
                </p>
                <p className="p-text">
                Nosso verdadeiro propósito, em duas decadas de aprendizado, vai além da acertividade, tratando-se também de muita criatividade. <br/> Acreditamos no que diz respeito a sinergia de um ambiente e, como isso faz a diferença em nossas vidas... Isso é o que somos e carregamos conosco! <br/> Não precisa perder tempo. Gostou? Põe Moldura!
                </p>
                </div>
            </div>
            <div className="three-image-container">
            <div className="bg-detail">
                <CgCoffee className="icon-image-three"/>
            </div>
            <img src={sobre}></img>
        </div>
    </div>
       
        <div className="first-container div-height">
            <div className="first-image-container">
                <div className="bg-detail">
                    <BsTruck className="icon-image-first"/>
                </div>
                <img src={sobre2}></img>
            </div>
            <div className="first-text-container">
                <div className="align-text-box">
                    <h1>
                    Como funciona o serviço de entrega?
                    </h1>
                    <p className="sub-tittle">
                    Para informações completas, contacte-nos
                    </p>
                    <p className="p-text">
                    Cobrimos entregas em toda a região de Jundiaí e cidades vizinhas, com algumas excessões. Para vendas no valor acima de R$500,00 a entrega é grátis! Para pedidos abaixo do valor citado, consulte no fechamento do seu pedido.   
                    </p>
                </div>
        </div>
    </div>
    <div className="three-container maps-parte">
        <div className="three-text-container">
            <div className="align-text-box">
            <h1>
                Como chegar na loja?
                </h1>
                <p className="p-text">
                    Nosso Endereço é Av. José Mezzalira, 930 - Bairro Caxambu - Jundiai/SP
                </p>
                </div>
            </div>
            <div className="three-image-container">
            <div className="bg-detail">
                <SiGooglemaps className="icon-image-three"/>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2310.9051129409204!2d-46.833205897982864!3d-23.15803396185881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8267dfe180399fb!2sF%C3%A1brica%20da%20Moldura!5e0!3m2!1spt-BR!2sbr!4v1649347797041!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    </div>
    
    );
}

export default SInfo