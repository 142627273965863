import React from 'react';

function complements(content){
 
    switch (content) {
        case '01':
            localStorage.setItem('servicos-container', '01');
            break
        case '02':
            localStorage.setItem('servicos-container', '02');
            break
    }
}
//
export default complements
