import React, { useEffect } from "react";
import MBanner from '../components/MBanner'
import Main from '../components/molduras/Main'
function Molduras(props){
    
    useEffect( () => {
        document.title = props.title
    }, []);
    
    return (
        <div className="moldura">
            <MBanner/>
            <Main tittle={props.title}/>
        </div>
    );
}

export default Molduras