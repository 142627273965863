import React, { useEffect } from "react";

import HBanner from '../components/HBanner'
import HQuemsomos from '../components/HQuemsomos'
import HPersonalizado from '../components/HPersonalizado'
import HSlidesmodelo from '../components/HSlidesmodelo'
import HQuestions from '../components/HQuestions'
import Fade from 'react-reveal/Fade'
import complements from '../components/store/complements'

function Home(props){
    
    useEffect( () => {
        document.title = props.title;
        if(localStorage.getItem('servicos-container') == '01'){
            complements('02');
            setTimeout(() => {
                window.scroll(0, 
                    findPosition(document.getElementById("tittle-h1-hs"))
                    ); 
            }, 1000);
            function findPosition(obj) {
                var currenttop = 0;
                if (obj.offsetParent) {
                    do {
                        currenttop += obj.offsetTop;
                    } while ((obj = obj.offsetParent));
                    return [currenttop];
                }}}
    }, []);

    return(
        <div className="home">
            <HBanner/>
            <div className="content-max">
            <Fade left>
                <HQuemsomos/>
            </Fade>
            <Fade right>
                <HPersonalizado/>
            </Fade>
            <Fade left>
                <HSlidesmodelo/>
            </Fade>
            <Fade right>
                <HQuestions/>
            </Fade>
 
            <br/>
            </div>
            
        </div>
    );
}

export default Home