import React, { useEffect } from "react";
import "./css/sobre.css"
import { Carousel, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom'

function SBanner(props){

    const navigate = useNavigate();
    function onChange(a, b, c) {
        console.log(a, b, c);
    }

    useEffect(()=>{
        document.getElementById('tittle-page').classList.add('active');
    }, []);

    return(<>
        <div className="banner-sobre">
             <Carousel afterChange={onChange}>
                <div >
                    <div className="cont-img-sobre">
                    <h1 id="tittle-page">Sobre Nós</h1>
                    </div>
                
                </div>
             </Carousel> 
        </div>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate('/')}
            title={props.tittle}
            subTitle="Seleção"
        />
    </>);

}

export default SBanner


