import React from "react";
import { Carousel, Button } from 'antd';
import {Link} from 'react-router-dom'
import img1 from '../imgs/carros.png'
import img2 from '../imgs/colher.png'
import img3 from '../imgs/medalhas.png'
import imgLogo from '../imgs/icon-logo.png';
import Fade from 'react-reveal/Fade';

function HPersonalizado(){
 
    return(
        <div className="box-hp">
            <div className="container-hp">
                <div className="text-hp">
                <Fade top>
                       <h1>Venha Personalizar<img src={imgLogo} className="banner1"></img></h1>
                </Fade>
                        <p>
                             De imagens e quebra-cabeças, até camisetas e objetos. <br/> temos a solução ideal para você!
                        </p>
                        <Link to="/orcamento"><Button type="primary">Fazer Orçamento</Button></Link>
                </div>
                <div className="img-hp">
                        <div className="circle"></div>
                        <div className="carousel-box">
                        <Carousel autoplay className="carousel-item">
                            <div>
                                <div className="box-item">
                                    <img src={img1}></img>
                                </div>
                            </div>
                            <div>
                                <div className="box-item">
                                    <img src={img2}></img>
                                </div>
                            </div>
                            <div>
                                <div className="box-item">
                                    <img id="img3-item" src={img3}></img>
                                </div>
                            </div>
                        </Carousel>
                        </div>
                </div>
            </div>
        </div>
    );

}

export default HPersonalizado


