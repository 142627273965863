import React, {useEffect, useState} from "react";
import LogoNav from "../../imgs/logo_branco.png";
import './Nav.css';
import { Link, useNavigate } from 'react-router-dom'
import { BackTop } from 'antd';
import { FaWhatsapp } from 'react-icons/fa'
import { BiMenu, BiX } from 'react-icons/bi'
import complements from '../store/complements'

function Nav(){
    const navigate = useNavigate();
    const [pageContent , SetpageContent] = useState(false);
    const pathname = window.location.pathname;

    function MobileControl(valor){
        SetpageContent(valor);
   
    }
    function Service(){
        complements('01');
        if(pathname == '/'){
            window.location.reload();
        }else{
            navigate('/');
        }
        

    }
    useEffect(() => {
        var nav1 = document.getElementById('page-mobile');
        if(pageContent == true){
            nav1.classList.add('active');
        }else{
            nav1.classList.remove('active');
        }
    }, [pageContent]);


    return(
        <>
        <div id="page-mobile" className="page-mobile">
           <div className="header-mobile-top">
               <div className="img-logo">
               <Link to="/" onClick={() => { MobileControl(false)}}><img src={LogoNav} alt="Inicio"></img></Link>
               </div>
               <div className="exit-s" onClick={() => { MobileControl(false) }}>
                <BiX className="svg-exit"/>
                </div> 
           </div>
           <ul className="ul-nav">
            <a onClick={() => { Service(); MobileControl(false)}}><li id="link-servicos">Serviços</li></a>
            
            <Link to="/molduras" onClick={() => { MobileControl(false)}}><li id="link-molduras">Molduras</li></Link>

            <Link to="/sobre" onClick={() => { MobileControl(false)}}><li id="link-sobrenos">Sobre nós</li></Link>
                        
            <Link to="/orcamento" onClick={() => { MobileControl(false)}}><li id="link-contatos">Contate-nos</li></Link>
            <a href="https://loja.fabricadamoldura.com.br/"><li>Loja Virtual</li></a>
            </ul>

        </div>
        <nav className="nav-container">
            <div className="content-nav">
                    <Link to="/"><img src={LogoNav} alt="Inicio"></img></Link>
                <ul className="ul-nav">

                    <a onClick={Service}><li id="link-servicos">Serviços</li></a>

                    
                    
                    <Link to="/molduras"><li id="link-molduras">Molduras</li></Link>
                    
                   <Link to="/sobre"><li id="link-sobrenos">Sobre nós</li></Link>
                    
                                    
                    <Link to="/orcamento" ><li id="link-contatos">Contate-nos</li></Link>
                    <a href="https://loja.fabricadamoldura.com.br/"><li>Loja Virtual</li></a>   
                </ul>
                <div className="nav-mobile" onClick={() => { MobileControl(true)}} >
                <BiMenu/>
                </div>  
            </div>
            <a href="https://api.whatsapp.com/send?phone=5511980718695&text=Hor%C3%A1rios:%20Seg/%20a%20sexta%20das%2009h%20%C3%A0s%2017h,%20aos%20s%C3%A1bados%20das%2009h%20as%2013h!">
            <BackTop className="scrollTop-main">
                <div className="scrollTop"><FaWhatsapp/></div>
            </BackTop></a>
        </nav>
        </>
    )
}

export default Nav

