import React from "react";
import img1 from "../imgs/hq_1.JPG"
import img2 from "../imgs/hq_2.JPG"
import img3 from "../imgs/hq_3.jpg"
import { Carousel } from 'antd';
import { FaTruckLoading, FaStore } from "react-icons/fa";
import { MdOutlineFilterFrames } from "react-icons/md";
import Fade from 'react-reveal/Fade';

function HQuemsomos(){

    const Content = [
        {
            h4: "Compre Online",
            p: "Quadros decorativos e porta-retratos a pronta entrega.",
            titleLINK: "Saiba Mais",
            Link: "https://loja.fabricadamoldura.com.br/"
        },
        {
            h4: "Personalize",
            p: "Um inventário com mais de 300 molduras para emoldurar seu quadro!",
            titleLINK: "Saiba Mais",
            Link: "https://www.fabricadamoldura.com.br/molduras"
        },
        {
            h4: "Receba em Casa",
            p: "Dos pequenos até 5 metros, nós levamos até você! Confira as condições.",
            titleLINK: "Saiba Mais",
            Link: "https://www.fabricadamoldura.com.br/sobre"
        }
    ]

    return(
        <div className="box-hq">
            <div className="h3-hq">
            <Fade bottom>

                    <h3>Um pouco de nós...<hr></hr></h3>      
                </Fade>
                
            </div>
            <ul className="content-ul-hq">
                  <li className="item-hq">
                    <div className="img-icon">
                        <img src={img1}></img>
                        <div className="icon-item">
                            <FaStore/>
                        </div>
                    </div>
                    <div className="text-hq">
                        <h4>{Content[0].h4}</h4>
                        <p>{Content[0].p}</p>
                        <a href={Content[0].Link}>{Content[0].titleLINK}</a>
                    </div>
                  </li>
                  <li className="item-hq">
                    <div className="img-icon">
                        <img src={img2}></img>
                        <div className="icon-item">
                        <MdOutlineFilterFrames/>
                        </div>
                    </div>
                    <div className="text-hq">
                    <h4>{Content[1].h4}</h4>
                        <p>{Content[1].p}</p>
                        <a href={Content[1].Link}>{Content[1].titleLINK}</a>
                    </div>
                  </li>
                  <li className="item-hq">
                    <div className="img-icon">
                        <div className="img-color-hq">
                            <img src={img3}>
                                
                                </img>
                        </div>
                        
                        <div className="icon-item">
                        <FaTruckLoading/>
                        </div>
                    </div>
                    <div className="text-hq">
                    <h4>{Content[2].h4}</h4>
                        <p>{Content[2].p}</p>
                        <a href={Content[2].Link}>{Content[2].titleLINK}</a>
                    </div>
                  </li>
              </ul>
            <div className="container-mobile">
                <Carousel autoplay className="container-mobile-hq">
                    <div>
                        <li className="item-hq">
                            <div className="img-icon">
                                <img src={img1}></img>
                                <div className="icon-item">
                                    <FaStore/>
                                </div>
                            </div>
                            <div className="text-hq">
                                <h4>{Content[0].h4}</h4>
                                <p>{Content[0].p}</p>
                                <a href={Content[0].Link}>{Content[0].titleLINK}</a>
                            </div>
                        </li>
                    </div>
                    <div>
                            <li className="item-hq">
                                <div className="img-icon">
                                    <img src={img2}></img>
                                    <div className="icon-item">
                                        <FaStore/>
                                    </div>
                                </div>
                                <div className="text-hq">
                                    <h4>{Content[1].h4}</h4>
                                    <p>{Content[1].p}</p>
                                    <a href={Content[1].Link}>{Content[1].titleLINK}</a>
                                </div>
                            </li>
                    </div>
                    <div>
                            <li className="item-hq">
                                <div className="img-icon">
                                    <img src={img3}></img>
                                    <div className="icon-item">
                                        <FaStore/>
                                    </div>
                                </div>
                                <div className="text-hq">
                                    <h4>{Content[2].h4}</h4>
                                    <p>{Content[2].p}</p>
                                    <a href={Content[2].Link}>{Content[2].titleLINK}</a>
                                </div>
                            </li>
                    </div>
                </Carousel>
              </div>
              <div className="p-hq">
                <p>Venha saber mais aqui no <a href="https://www.fabricadamoldura.com.br/sobre">Link</a></p>
            </div>
        </div>
    );

}

export default HQuemsomos


