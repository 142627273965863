import React, { useEffect, useState } from "react";
import {RemoveOrcamento} from './store/SelectorOrcamento'
import { message, Result, Button } from 'antd';
import {Bases} from './services/api_molduras';
function OConteudo(){

    const [textomoldura, setTextomoldura] = useState('');
    useEffect(() => {
        if(localStorage.getItem('moldura-selecionada')){
            setTextomoldura("Código da moldura selecionada do catálogo: (" + localStorage.getItem('moldura-selecionada') + ')\n');
            RemoveOrcamento();
            
        }
    }, [])
    const error = () => {
        message.error('Responda o Formulario por completo');
      };
    function EnviarFormulario(params) {
        params.preventDefault();
        const formBase = document.getElementById('form-base');
        const nomeInput = document.getElementById('nome-input').value;
        const emailInput = document.getElementById('email-input').value;
        const tellInput = document.getElementById('tell-input').value;
        const respostaInput = document.getElementById('resposta-input').value;
        if(nomeInput != '' && emailInput != '' && tellInput != '' && respostaInput != ''){
        var orcamento = [
                    nomeInput,
                    emailInput,
                    tellInput,
                    respostaInput
        ];

                Bases.post('load.php?orcamento='+orcamento).then(function (response){
                    console.log(response)
                })
                formBase.classList.add('active');
                
                console.log(orcamento);
        }else{
            error();
        }
        
       

    }

    
    return (
        <div className="conteudo-oc">
    <div className="personalizar-container orcamento">
        <div className="btn-green">
                <div className="green-tab">

                </div>
        </div>
                <div className="tittle-container">
                    <h2 >
                        Faça seu orçamento personalizado!
            
                    </h2>
                <p>
                Conte-nos sua ideia ou descreva medidas para um quadro. Entraremos em contato via e-mail ou mensagem, conforme melhor julgarmos (não enviamos spam)
                </p>
                </div>
               

                <div id="form-concluido" className="anime form-container">
                    <div className="vector-concluido">
                    <Result
                        status="success"
                        title="Orçamento Enviado Com Sucesso"
                        subTitle="Avisaremos pelo seu Email, fique atento"
                    />
                    </div>
                    <form id="form-base" className="form-from">     
                        <div className="input-table">
                            <p>
                                Nome<span>*</span>
                            </p>
                            <input type="text" name="nome" required id="nome-input" className="input-type" maxLength="55px"/>
                        </div>
                        <div className="input-table table-double">
                            <div className="input-div">
                                <p>
                                    E-mail<span>*</span>
                                </p>
                                <input type="email" name="email" required id="email-input" className="input-type" maxLength="70px"/>
                            </div>
                            <div className="input-div">
                                <p>
                                    Telefone<span>*</span>
                                </p>
                                <input type="number" name="tell" id="tell-input" className="input-type" required />
                            </div>
                            
                        </div>
                        <div className="input-table textareas">
                            <p>
                                Nos conte do que precisa<span>*</span>
                            </p>
                            <textarea  required placeholder="Escreva o que precisa!" defaultValue={textomoldura} name="resposta" id="resposta-input" cols="30" rows="10" className="input-type textarea">
                                    
                            </textarea>
                            <input onClick={EnviarFormulario} type="submit" id="submit" value="Enviar"/>
                        </div>
                    </form> 
              
                    </div>
                </div>
            </div>
    );
}

export default OConteudo