import React, { useState } from "react";
import {Bases} from '../services/api_molduras'
import { Modal, Button, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import {SelectorOrcamento} from '../store/SelectorOrcamento'


function Produto(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false); 
    const [conteudoEscolhido , setConteudoEscolhido] = useState();
    const [load , setLoad] = useState(false);
    const [estoque , setEstoque] = useState('');
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
     
    const error = (messagem) => {
        message.error(messagem, 7);
      };
      const showModal = (valor) => {
        setVisible(true);
        setLoad(true);
        Bases.get('account.php?BuscarSelecionado='+valor).then(function (response){
            const retorno = response.data;
          
            if(retorno[0].stock != '0'){
                error('O Estoque dessa moldura está acabando! \n Consulte o Estoque Durante o Pedido')
            }

            setConteudoEscolhido(<>
                <div className="content-model-base">
                    <div className="image-model-base">
                        {/* <img className="back-model-base" src={Svg}></img> */}
                        <img className="image-item-base" src={retorno[0].url}></img>
                    </div>
                    <div className="text-model-base">
                        <div>
                            Codigo: {retorno[0].name}
                        </div>
                        <ul>
                            <li>
                                <b>Altura</b>: {retorno[0].altura}
                            </li>
                            <li>
                                <b>Largura</b>: {retorno[0].largura}    
                            </li><br></br>
                            <li>
                                <b>Tipo</b>: {retorno[0].types}   
                            </li>
                            <li>
                                <b>Cor</b>: {retorno[0].color}   
                            </li>
                        </ul>
                       
                    </div>
                </div>        
            </>)

        setInterval(() => {
            setLoad(false);
        }, 100);
        
         })
      };
    
      const handleOk = () => {
        setLoading(true);
        SelectorOrcamento(props.sku);


        setTimeout(() => {
            setLoading(false);
            setVisible(false);
            navigate('/orcamento');
        }, 1000);
      };
      const handleCancel = () => {
        setVisible(false)
      };
    
        return(<>
             <Modal
          visible={visible}
          title={"Moldura N°: "+props.sku}
          onOk={handleOk}
          onCancel={handleCancel}
          width={650}
          footer={[
            <Button className="button-modal" key="submit" type="primary" loading={loading} onClick={handleOk}>
              Consulte o orçamento
              
            </Button>
          ]}
        >
            <Spin spinning={load} indicator={antIcon} >
                {conteudoEscolhido}
            </Spin>
          

        </Modal>
        <div className="product-moldu" onClick={() => {showModal(props.keys)} }>
            <div className="img-container-moldu">
                <img className="img-moldu" src={props.thumb}></img>
            </div>
            <div className="text-moldu">
                    {props.sku}
            </div>
    
        </div>
        
        
        </>)
}

export default Produto