import React from "react";
import moldu1 from '../imgs/modelos/caixaalta.png'
import moldu2 from '../imgs/modelos/lisas2.png'
import moldu3 from '../imgs/modelos/talhadas.png'
import mont1 from '../imgs/modelos/passepartout.png'
import mont2 from '../imgs/modelos/simples.png'
import mont3 from '../imgs/modelos/vidroduplo.png'
import bg1 from '../imgs/back-drop/Capturar.PNG'
import bg2 from '../imgs/back-drop/Capturar1.PNG'
import bg3 from '../imgs/back-drop/shape.png'

export function Hmolduras(){
    return (
    <div className="moldura-hs">
                      <div className="item-moldura-box-hs">
                        <div className="div-ul-hs moldura-img-hs">
                            <div className="img-div-hs">
                              <img className="bg-item-hs" src={bg1}></img>
                              <img className="img-moldu-hs" src={moldu2}></img>
                            </div>
                        </div>
                        <div className="div-ul-hs moldura-text-hs">
                           <h2>
                           Lisas
                           </h2>
                           <p>
                           Com design simples, os modelos encaixam-se facilmente em diversos casos!
                           </p>
                        </div>
                      </div>
                      <div className="item-moldura-box-hs">
                        <div className="div-ul-hs moldura-text-hs">
                            <h2>
                               Caixa Alta
                           </h2>
                           <p>
                           Ideais para montagens mais personalizadas, desde telas de pintura até objetos de valor pessoal...
                           </p>
                        </div>
                        <div  className="div-ul-hs moldura-img-hs">
                        <div className="img-div-hs">
                        <img className="bg-item-hs" src={bg2}></img>
                              <img className="img-moldu-hs" src={moldu1}></img>
                        </div>
                        </div>
                      </div>
                      <div className="item-moldura-box-hs">
                        <div  className="div-ul-hs moldura-img-hs">
                        <div className="img-div-hs">
                        <img className="bg-item-hs" src={bg3}></img>
                              <img className="img-moldu-hs" src={moldu3}></img>
                            </div>
                        </div>
                        <div className="div-ul-hs moldura-text-hs">
                            <h2>
                                Detalhadas
                           </h2>
                           <p>
                           Temos um amplo acervo de opções arrojadas. Essas molduras proporcionam destaque ímpar as paredes!
                           </p>
                        </div>
                      </div>
    </div>
    )        
};

export function Hmontagem(){
    return (  
    <div className="moldura-hs">
    <div className="item-moldura-box-hs">
      <div className="div-ul-hs moldura-img-hs">
          <div className="img-div-hs">
            <img className="bg-item-hs" src={bg1}></img>
            <img className="img-moldu-hs" src={mont1}></img>
          </div>
      </div>
      <div className="div-ul-hs moldura-text-hs">
         <h2>
         Passepartout
         </h2>
         <p>
         O estilo trata o foco principal combinado de uma composição em cartão e vidro. Possibilita um <br/> maior realce e estilo, além de proteção extra <br/> ao item emoldurado.
         </p>
      </div>
    </div>
    <div className="item-moldura-box-hs">
      <div className="div-ul-hs moldura-text-hs">
          <h2>
          Simples
         </h2>
         <p>
         Como o próprio nome.<br/> Combina em diversos estilos de moldura e, trata-se exclusivamente de uma imagem/tela com moldura.
         </p>
      </div>
      <div  className="div-ul-hs moldura-img-hs">
      <div className="img-div-hs">
      <img className="bg-item-hs" src={bg2}></img>
            <img className="img-moldu-hs" src={mont2}></img>
      </div>
      </div>
    </div>
    <div className="item-moldura-box-hs">
      <div  className="div-ul-hs moldura-img-hs">
      <div className="img-div-hs">
      <img className="bg-item-hs" src={bg3}></img>
            <img className="img-moldu-hs" src={mont3}></img>
          </div>
      </div>
      <div className="div-ul-hs moldura-text-hs">
          <h2>
          Vidro Duplo
         </h2>
         <p>
         Nessa composição, o item emoldurado é colocado entre duas faces de vidro, que gera uma impressão "flutuante". <br/> Pode ser feito em molduras caixa alta e compor o passepartourt também, caso seja desejável.
         </p>
      </div>
    </div>
</div>
    )
}