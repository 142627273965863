import React, { useEffect } from "react";
import SBanner from '../components/SBanner'
import SInfo from '../components/SInfo'
import '../components/css/sobre.css'
import '../components/css/bootstrap.css'
import Fade from 'react-reveal/Fade'
function Sobre(props){
    
    useEffect( () => {
        document.title = props.title
    }, []);
    
    return (
        <div className="sobre">
            <SBanner tittle={props.title}/>
            <Fade left>
            <SInfo/>
            </Fade>
        </div>
    );
}

export default Sobre